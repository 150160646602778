import { Controller } from 'stimulus'
import tippy from 'tippy.js'

const filterAttr = 'data-filter-pill-filter-key-value'
const currentPageKey = 'currentPage'
const companyCurrentPageKey = 'companyCurrentPage'

export default class extends Controller {

  static targets = [
    'prospectList',
    'prospectListWrapper',
    'tabsNav',
    'pageNumber',
    'prospectCount',
    'sortDropdown',
    'tabSection',
    'showHide',
    'showHideToggle',
    'showHideToggleText',
    'targetCompaniesFilterPill',
  ]

  static values = {
    tabParam: String,
    targetCompaniesParam: Array,
    targetCompaniesIncludeParam: String,
    buyingCentersParam: Array,
    levelsParam: Array,
    relationshipStrengthParam: Array,
    affiliationRolesParam: Array,
    connectorsParam: Array,
    beastModeParam: Boolean,
    showHiddenParam: Boolean,
    sortByParam: String,
    pageParam: Number,
    personPage: Number,
    companyPage: Number,
    recordStart: Number,
    recordStop: Number,
    hasMore: Boolean,
    intentOnlyParam: Boolean
  }

  /************************************************************************************************
    STIMULUSJS LIFECYCLE METHODS
  ************************************************************************************************/

  initialize() {
    this.personPageValue = 0
    this.companyPageValue = 0
    this.recordStartValue = 0
    this.recordStopValue = 0
    this.hasMoreValue = false
  }

  connect() {
    // Set the page number for the first time
    this.setPageNumber(this.pageParamValue)

    // We need to initialize the Stimulus values that map to the filter pill checkboxes
    // We do this so that we are not duplicating the data transfer of IDs since target comapnies is a large list
    // The other stimulus values are passed in the view template via the data attribute
    this.getFilterPillElements().forEach(pill => {
      const pillName = pill.getAttribute(filterAttr)
      const checkedNodes = pill.querySelectorAll(`input[type="checkbox"]:checked`)
      const checkedValues = Array.prototype.map.call(checkedNodes, (node) => node.value)
      this.setParam(pillName, checkedValues)
    })

    // Set the query params and sync the browser bar and history
    this.setUrlState()

    // Set the sort dropdown
    this.setSortDropdown()

    // Set the show/hide toggle text
    this.setShowHideToggleText()

    // Initialize tooltips
    this.initializeTooltip()

    // Lock all target companies options if beast mode is enabled
    if (this.beastModeParamValue) this.dispatchFilterPillEvent('target_companies', 'selectAndDisableAll')

    // Reload page if back button is pressed
    window.addEventListener('popstate', (event) => event.state == null ? location.reload() : null)

    // Load the first tab
    if (window.location.href.indexOf("/v2/dashboard") > -1) this.handleTabToggle()
  }

  /************************************************************************************************
    EVENT HANDLER & DISPATCHER METHODS
  ************************************************************************************************/

  handleFilteredEvent(event) {    
    this.resetAllPageNumbers()
    this.load(event)
  }

  handleBeastModeToggle(event) {
    this.resetAllPageNumbers()
    this.beastModeParamValue = event.target?.checked || false
    const action = this.beastModeParamValue ? 'selectAndDisableAll' : 'selectAndEnableAll'
    this.dispatchFilterPillEvent('target_companies', action)
    this.load(event)
  }

  handleIntentOnlyToggle(event) {
    this.resetAllPageNumbers()
    this.intentOnlyParamValue = event.target?.checked || false
    this.load(event)
  }

  handleTabToggle(event) {
    const tab = this.getTab()
    this.tabParamValue = tab
    this.setShowHideToggleText()
    this.load(event)
  }

  handleNextPageClick(event) {
    this.incrementPageNumber()
    this.scrollToTop()
    this.load(event)
  }

  handlePreviousPageClick(event) {
    this.decrementPageNumber()
    this.scrollToTop()
    this.load(event)
  }

  handleSortChange(event) {
    this.sortByParamValue = this.sortDropdownTarget.value
    this.resetAllPageNumbers()
    this.load(event)
  }

  // Use this to dispatch events that specific filter pills can listen to and handle with the specified action
  // TODO: Move this to a common or base file
  dispatchFilterPillEvent(filterName, action) {
    document.dispatchEvent(new CustomEvent('filter-pill-event', {
      detail: {
        filterName: filterName,
        action: action
      } 
    }))
  }

  /************************************************************************************************
    NETWORK CALLS
  ************************************************************************************************/

  load(event) {
    // Start loading
    if (event && event.target) event.target.disabled = true
    this.prospectListWrapperTarget.classList.add('loading')

    this.setFilterPillParams()
    this.setUrlState()

    fetch(`${window.location.pathname}/prospects${window.location.search}`, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then(response => response.json())
      .then(data => {
        this.prospectListWrapperTarget.innerHTML = data.prospectList
        this.recordStartValue = data.start
        this.recordStopValue = data.stop
        this.hasMoreValue = data.hasMore
        this.setPageNumber(data.page)
        this.setRecordText()
        this.setPageNumberText()
      })
      .catch(e => console.error(e))
      .finally(() => {
        this.setUrlState()
        this.initializeTooltip()
        // Finish loading
        this.prospectListWrapperTarget.classList.remove('loading')
        if (event && event.target) event.target.disabled = false
      })
  }

  toggleProspectVisibility({ target }) {
    const tab = this.getTab()
    const prospectIsVisible = (target.dataset.prospectIsVisible === 'true')
    const prospectId = target.dataset.prospectId
    const prospectType = target.dataset.prospectType

    // If prospect is currently visible, make request to hide endpoint, otherwise unhide
    const url = `${window.location.origin}${window.location.pathname}/${prospectIsVisible ? 'hide' : 'unhide'}`

    // Get Show Hidden Prospects toggle status
    const checkbox = this.showHideToggleTarget.querySelector('input[type="checkbox"]')
    const showHiddenProspectsStatus = checkbox.checked

    // If the prospect is currently visible, we're going to hide it,
    // so we should toggle card text and status message appropriately
    const cardText = prospectIsVisible ? 'Unhide' : 'Hide'
    const prospectText = (prospectType === 'company') ? 'Company' : 'Prospect'
    const statusMessage = prospectIsVisible
      ? `The ${prospectText} was hidden from from your list of opportunities.`
      : `The ${prospectText} will always show in your list of opportunities.`

    fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': this.getCsrfToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        prospect_type: prospectType,
        prospect_id: prospectId
      })
    })
    .then(() => {
      // Prospect visibility attribute
      target.dataset.prospectIsVisible = !prospectIsVisible.toString()
      // Prospect card text
      target.innerHTML = cardText
      // If are toggling this card to hidden AND we are filtering out
      // hidden prospects globally, then hide this card
      if (prospectIsVisible && !showHiddenProspectsStatus) {
        // Find the parent list item tag and hide it
        const listItem = target.closest('li')
        if (listItem) {
          listItem.ontransitionend = () => listItem.remove()
          listItem.classList.remove('show')
        }
      }
      // Show status message
      toastr.remove()
      toastr.success(statusMessage)
    })
    .catch((err) => {
      console.error('Error....')
      console.error(err)
    })
  }

  /************************************************************************************************
    HELPER METHODS
  ************************************************************************************************/

  initializeTooltip() {
    tippy('[data-tippy-content]', {
      allowHTML: true,
      arrow: true,
      maxWidth: 200,
      inertia: true,
    })
  }
  
  scrollToTop() {
    // If the animated scroll is not working, revert to the following lines
    // document.body.scrollTop = 0; // For Safari
    // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  getTab() {
    const tab = (this.tabsNavTarget.querySelector('#nav-companies-tab').classList.contains('active')) ? 'companies' : 'people'
    this.tabParamValue = tab || this.tabParamValue
    return this.tabParamValue
  }

  getCsrfToken() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }

  getPageNumber() {
    return (this.getTab() === 'people') ? this.personPageValue : this.companyPageValue
  }

  setPageNumber(value) {
    if (this.getTab() === 'people') {
      this.personPageValue = value
      this.pageParamValue = this.personPageValue
    } else {
      this.companyPageValue = value
      this.pageParamValue = this.companyPageValue
    }
  }

  resetAllPageNumbers() {
    this.pageParamValue = 0
    this.personPageValue = 0
    this.companyPageValue = 0
    this.setUrlState()
  }

  incrementPageNumber() {
    this.setPageNumber(this.getPageNumber() + 1)
  }

  decrementPageNumber() {
    const newPageNumber = this.getPageNumber() - 1
    this.setPageNumber(newPageNumber < 0 ? 0 : newPageNumber)
  }

  getFilterPillElements() {
    return this.tabSectionTarget.querySelectorAll(`div[data-controller="filter-pill"]`)
  }

  getParam(key) {
    if (key === 'target_companies') return this.targetCompaniesParamValue
    if (key === 'target_companies_include') return this.targetCompaniesIncludeParamValue
    if (key === 'buying_centers')return this.buyingCentersParamValue
    if (key === 'levels') return this.levelsParamValue
    if (key === 'relationship_strength') return this.relationshipStrengthParamValue
    if (key === 'affiliation_roles') return this.affiliationRolesParamValue
    if (key === 'connectors') return this.connectorsParamValue
    if (key === 'beast_mode') return this.beastModeParamValue
    if (key === 'tab') return this.tabParamValue
    if (key === 'page') return this.getPageNumber()
    if (key === 'sort_by') return this.sortByParamValue
    if (key === 'show_hidden') return this.showHiddenParamValue
    if (key === 'intent_only') return this.intentOnlyParamValue
  }

  setParam(key, value) {
    if (key === 'target_companies') this.targetCompaniesParamValue = value
    if (key === 'target_companies_include') this.targetCompaniesIncludeParamValue = value
    if (key === 'buying_centers') this.buyingCentersParamValue = value
    if (key === 'levels') this.levelsParamValue = value
    if (key === 'relationship_strength') this.relationshipStrengthParamValue = value
    if (key === 'affiliation_roles') this.affiliationRolesParamValue = value
    if (key === 'connectors') this.connectorsParamValue = value
    if (key === 'beast_mode') this.beastModeParamValue = value
    if (key === 'tab') this.tabParamValue = value
    if (key === 'page') this.setPageNumber(value)
    if (key === 'sort_by') this.sortByParamValue = value
    if (key === 'show_hidden') this.showHiddenParamValue = value
    if (key === 'intent_only') this.intentOnlyParamValue = value
  }

  setPageNumberText() {
    if (this.hasPageNumberTarget) this.pageNumberTarget.innerHTML = `Page ${this.getPageNumber() + 1}`
  }

  setRecordText() {
    const prospectType = (this.getTab() === 'people') ? 'People' : 'Companies'
    this.prospectCountTarget.innerHTML = `Showing ${this.recordStartValue}-${this.recordStopValue} of ${this.recordStopValue}${this.hasMoreValue ? '+' : ''} ${prospectType}`
    if (this.getPageNumber() <= 0 && !this.hasMoreValue) {
      this.prospectCountTarget.parentElement.classList.add('hide')
    } else {
      this.prospectCountTarget.parentElement.classList.remove('hide')
    }
  }

  setSortDropdown() {
    this.sortDropdownTarget.value = this.sortByParamValue
  }

  setShowHideToggleText() {
    const prospectType = (this.getTab() === 'people') ? 'People' : 'Companies'
    this.showHideToggleTextTarget.innerHTML = `Show Hidden ${prospectType}`
  }

  setUrlState() {
    var url = new URL(window.location.href)
    url.searchParams.set('tab', this.tabParamValue)
    url.searchParams.set('target_companies', this.targetCompaniesParamValue)
    url.searchParams.set('target_companies_include', this.targetCompaniesIncludeParamValue)
    url.searchParams.set('buying_centers', this.buyingCentersParamValue)
    url.searchParams.set('levels', this.levelsParamValue)
    url.searchParams.set('relationship_strength', this.relationshipStrengthParamValue)
    url.searchParams.set('affiliation_roles', this.affiliationRolesParamValue)
    url.searchParams.set('connectors', this.connectorsParamValue)
    url.searchParams.set('beast_mode', this.beastModeParamValue)
    url.searchParams.set('show_hidden', this.showHiddenParamValue)
    url.searchParams.set('sort_by', this.sortByParamValue)
    url.searchParams.set('page', this.getPageNumber())
    url.searchParams.set('intent_only', this.intentOnlyParamValue)
    window.history.replaceState(null, null, url)
  }

  setFilterPillParams() {
    this.getFilterPillElements().forEach(pill => {
      const pillName = pill.getAttribute(filterAttr)
      const checkedNodes = pill.querySelectorAll(`input[type="checkbox"]:checked`)
      const checkedValues = Array.prototype.map.call(checkedNodes, (node) => node.value)
      this.setParam(pillName, checkedValues)

      // Special handling for target companies to prevent cookie overflow due to URL length
      if (pillName === 'target_companies') {
        const uncheckedNodes = pill.querySelectorAll(`input[type="checkbox"]:not(:checked)`)
        const uncheckedValues = Array.prototype.map.call(uncheckedNodes, (node) => node.value)

        // If CHECKED is empty, build an empty INCLUDE list (this represents a "select none" scenario)
        if (checkedValues.length === 0) {
          this.setParam('target_companies', checkedValues)
          this.setParam('target_companies_include', true)
          return
        }

        // If UNCHECKED is empty, build an empty EXCLUDE list (this represents a "select all" scenario)
        if (uncheckedValues.length === 0) {
          this.setParam('target_companies', uncheckedValues)
          this.setParam('target_companies_include', false)
          return
        }

        // Now let's handle limits
        // Why do we choose 100?
        // Most browsers nowadays have a very very high character limit for URLs, so this is not the bottleneck
        // The bottleneck is the cookie size limit, which is 4096 bytes
        // Let's assume the number of digits in a company ID is 8; given our current company ID range, this is a safe assumption
        // as it would mean we have at least 100 million companies in our system
        // Next, let's take into account URL encoding for the comma; this resolves to three characters (%2C)
        // So, we have 8 + 3 = 11 characters per company ID multipled by 100 companies, we get 1100 characters
        // This should leave us overhead for other URL parameters and still stay under 50% of the cookie limit
        const limit = 100

        // If CHECKED <= LIMIT, always build an INCLUDE list
        if (checkedValues.length <= limit) {
          this.setParam('target_companies', checkedValues)
          this.setParam('target_companies_include', true)
          return
        }

        // If CHECKED > LIMIT and UNCHECKED <= LIMIT, build an EXCLUDE list
        if (checkedValues.length > limit && uncheckedValues.length <= limit) {
          this.setParam('target_companies', uncheckedValues)
          this.setParam('target_companies_include', false)
          return
        }

        // If CHECKED >= LIMIT and UNCHECKED >= LIMIT, then choose the smaller list and take the first N elements up to the limit
        // This is a major edge case, but we need to handle it
        // To encounter this, a user would have to have at least 200 target companies in their list
        // And then actively uncheck half of them -- that's unchecking (or checking) 100 check boxes -- which is a lot of effort
        // TODO: PROVIDE FEEDBACK IN THE UI THAT THERE IS A LIMIT TO THE NUMBER OF CHECKED VALUES, AND ENFORCE IT
        if (checkedValues.length >= limit && uncheckedValues.length >= limit) {
          if (checkedValues.length < uncheckedValues.length) {
            this.setParam('target_companies', checkedValues.slice(0, limit))
            this.setParam('target_companies_include', true)
            return
          } else {
            this.setParam('target_companies', uncheckedValues.slice(0, limit))
            this.setParam('target_companies_include', false)
            return
          }
        }

        // Finally, if we somehow get here, then we have an unaccounted for scenario
        // Let's just return all target companies and throw a console error
        this.setParam('target_companies', [])
        this.setParam('target_companies_include', false)
        console.error('Unaccounted for target company filter')
      }
    })
  }
}
