import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [ "arrow", "object", "category", "found" ];
    static values = { 
        fieldName: String,
        env: String
     }

    toggleExpansion(event) {
        const arrowTarget = event.target;
        const container = this.getContainerForArrow(arrowTarget);
        container.classList.toggle('expanded');
    }

    getContainerForArrow(arrowTarget) {
        return arrowTarget.closest('.expansion-container');
    }

    toggleIntentSource(event) {
        const source = event.target.dataset.source;
        const oldValue = !event.target.checked;
        
        const handleError = (err) => {
            toastr.error("Error toggling intent option " + source)
            console.error('Error....')
            console.error(err)
            event.target.checked = oldValue; // Revert the selector
        }
        
        const handleSuccess = (payload) => {
            const newValue = payload[source.toLowerCase()];
            if (newValue === undefined) {
                handleError("Error parsing response: " + JSON.dumps(payload))
                return;
            }

            const message = newValue
                ? "You will now see intent data from " + source
                : "You will no longer see intent data from " + source
            event.target.checked = newValue
            toastr.success(message);
        }

        fetch(`/toggle_intent/${source.toLowerCase()}`)
            .then(response => {
                if (response.ok) {
                    response.json().then(handleSuccess)
                } else {
                    handleError(response)
                }
            })
            .catch(handleError)
    }

    connect() {
        if (this.arrowTarget.dataset.orientation == 'down') {
            this.arrowTarget.style.transform = 'rotate(0deg)';
            document.querySelector("#reveal").classList.remove('hide');
            // If TC Candidates or Metadata get wiped out for some reason and there is still TC Criteria for an Account, the if will execute.
            document.querySelector(".available").classList.add('hide');
            document.querySelector(".switch").classList.remove('hide');
            document.querySelector("#toggle-sync-label").classList.remove('hide');
            const objElem = document.querySelector(".object_name");
            const fieldElem = document.querySelector(".column_name");
            objElem.classList.remove("hidden");
            fieldElem.classList.remove("hidden");
            document.querySelector("#spinner").classList.add('hide');
        }
        if (this.buttonsClicked()) {
          this.scrollToBottom();
          localStorage.setItem('buttonsClicked', 'false');
        }
    }

    deleteCriteria() {
        const criteriaContainer = document.querySelector("#criteria-div :nth-child(4)");
        const criteriaElem = event.target.parentElement.parentElement;
        if (criteriaContainer != criteriaElem) {
            criteriaElem.remove();
        }
    }

    addCriteria() {
        // Get our elements
        const elemToClone = event.target.parentElement.parentElement;
        let elemToModify = event.target.parentElement.parentElement;
        const targetAddDiv = document.querySelector("#criteria-div");
        // Copy the add row from the "add" click
        const valueRowClone = elemToClone.cloneNode(true);
        valueRowClone.querySelector(".object_name").classList.add("hidden");
        valueRowClone.querySelector(".column_name").classList.add("hidden");
        // Change buttons and reveal "operator" element
        elemToModify.querySelector(".btn-add").classList.add("hide");
        elemToModify.querySelector(".btn-delete").classList.remove("hide");
        elemToModify.querySelector(".operator").classList.remove("hide");
        elemToModify.querySelector("#value").required = true;
        //  Get the target that we will add the new row to    
        let valueText = valueRowClone.querySelector("#value");
        valueText.value =  null;
        targetAddDiv.append(valueRowClone);
        }

    addCategory() {
        const valueElem = event.target.parentElement.parentElement;
        let elemToModify = valueElem;
        const targetElem = valueElem.parentElement;
        const valueClone = valueElem.cloneNode(true);
        valueClone.querySelector('input').value = null;
        elemToModify.querySelector(".operator").classList.remove("hide");
        elemToModify.querySelector(".btn-add").classList.add("hide");
        elemToModify.querySelector(".btn-delete").classList.remove("hide");
        targetElem.append(valueClone);
    }

    deleteCategory() {
        const targetElem = event.target.parentElement.parentElement;
        targetElem.remove();
    }

    objectChange() {
        if (document.querySelector('#object_name').value == "Account") {
            document.querySelectorAll('[data-object="Account"]').forEach(option => option.classList.remove('hide'));
            document.querySelectorAll('[data-object="Opportunity"]').forEach(option => option.classList.add('hide'));
        } else if (document.querySelector('#object_name').value == "Opportunity") {
            document.querySelectorAll('[data-object="Opportunity"]').forEach(option => option.classList.remove('hide'));
            document.querySelectorAll('[data-object="Account"]').forEach(option => option.classList.add('hide'));
        }
    }

    arrowChange() {
        const setupRequired = document.querySelector(".setup")
        if (this.arrowTarget.dataset.orientation == 'right' && !setupRequired) {
            this.arrowTarget.style.transform = 'rotate(0deg)';
            this.arrowTarget.dataset.orientation = 'down';
            document.querySelector("#reveal").classList.remove('hide')
            const objElem = document.querySelector(".object_name");
            const fieldElem = document.querySelector(".column_name");
            objElem.classList.remove("hidden");
            fieldElem.classList.remove("hidden");
            document.querySelector("#spinner").classList.add('hide');
            document.querySelector(".available").classList.add('hide');
            this.objectChange();
        } else {
            this.arrowTarget.style.transform = 'rotate(270deg)'
            this.arrowTarget.dataset.orientation = 'right';
            const availElem = document.querySelector(".available")
            if (availElem) {
                availElem.classList.remove('hide');
            }
            if (setupRequired) {
                setupRequired.classList.remove('hide');
            }
            document.querySelector("#reveal").classList.add('hide')
        }
    }

    catObjectChange() {
        if (this.categoryTarget.value == "Account") {
            document.querySelectorAll('[data-cat="Account"]').forEach(option => option.classList.remove('hide'));
            document.querySelectorAll('[data-cat="Opportunity"]').forEach(option => option.classList.add('hide'));
        } else if (this.categoryTarget.value == "Opportunity") {
            document.querySelectorAll('[data-cat="Opportunity"]').forEach(option => option.classList.remove('hide'));
            document.querySelectorAll('[data-cat="Account"]').forEach(option => option.classList.add('hide'));
        }
    }

    checkLogicEvent() {
        document.querySelector("#spinner").classList.remove('hide');
        this.foundTarget.classList.remove('hide');
        localStorage.setItem('buttonsClicked', 'true');
    }
    
    updateEvent() {
        document.querySelector("#spinner").classList.remove('hide');
        this.foundTarget.classList.add('hide');
        localStorage.setItem('buttonsClicked', 'true');
    }

    toggleSync() {
        let toggle_value = document.querySelector('#toggle_sync').checked;
        fetch(`/toggle_sync/${toggle_value}`)
        .then(response=> {
        })
        .catch((err) => {
            console.error('Error....')
            console.error(err)
            })
        }
    
    buttonsClicked() {
        const buttonsClicked = localStorage.getItem('buttonsClicked');
        return buttonsClicked === 'true';
    }
    
    scrollToBottom() {
        window.scrollTo({
                top: document.body.scrollHeight,
                left: 0,
                behavior: "instant"
        });
    }
}
