import { Controller } from "stimulus";

async function tryFetch(url, options = {}) {
  // Outer try catches network errors
  try {
    const response = await fetch(url, options)

    // Check for HTTP error codes
    if (!response.ok) {
      console.error("HTTP error", response)
      return { err: `HTTP error ${response.status}`, res: null }
    }

    return { err: null, res: response }
  } catch (e) {
    console.error("Error fetching", e)
    return { err: "Network error", res: null }
  }
}

export default class extends Controller {
  static targets = ['connector', 'emailTemplate', 'form', 'prospectEmail', 'prospectEmailInput', 'submitButton',
    'prospect', 'relationship', 'pos', 'ghostEmail', 'body', 'requestMethod'];

  static values = {
    prospect: String,
    company: String,
    category: String,
    requester: String,
    generate: Boolean,
    emailClientAccess: String,
    emailProvider: String
  };

  noConnectorConfig = {
    strength: null,
    creationSource: null,
    solid: false,
    weak: false,
    inferred: false,
    source: {
      firstName: null,
      lastName: null,
    }
  };

  introReqConfig = {
    selectedConnector: {},
    salutation: '',
    ackContent: '',
    categoryContent: '',
    posContent: '',
    hasGhostContent: '',
    theAsk: '',
    closing: '',
  };

  connect() {
    this.selectOnlyCard();
    this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    this.connectorSelected = (this.connectorTargets.length === 1) ? true : false;
    this.posSelected = false;
    this.posTarget.addEventListener('change', () => this.validatePosDropdown())
    this.prospectEmailInputTarget.addEventListener('input', () => this.toggleSubmitButton())
  }

  selectConnector(event) {
    const connectorCard = event.currentTarget;
    const radioButton = connectorCard.querySelector('input[type=radio]');
    const disabled = !!connectorCard.querySelector('input[disabled=disabled]');

    const conditions = {
      userIntroPresent: radioButton.dataset.userIntroPresent === 'true',
      accountIntroPresent: radioButton.dataset.accountIntroPresent === 'true',
      userHasRelationship: radioButton.dataset.userHasRelationship === 'true',
      disabled: disabled,
    };

    const alertMessages = {
      userIntroPresent: 'You have an active introduction outstanding with this target prospect! Please continue with that introduction before requesting another.',
      accountIntroPresent: 'There is already an active introduction with this prospect on this account! Please contact your Account Admin or SmallWorld Support if there is an issue.',
      userHasRelationship: 'You cannot request an Introduction from yourself. Please select another option.',
      disabled: 'There is already an active introduction with this prospect on this account!'
    };

    for (let condition in alertMessages) {
      if (conditions[condition]) {
        alert(alertMessages[condition]);
        return;
      }
    }
    connectorCard.classList.add("selected");
    connectorCard.classList.remove("ineligible");

    if (event.params.rel) {
      this.restoreEmailOption()
    } else {
      this.disableEmailOption()
    }

    if (this.generateValue) {
      if (event.params.rel) {
        this.introReqConfig.selectedConnector = event.params.rel;
        this.connectorSelected = true;
      } else {
        this.connectorSelected = true;
        this.introReqConfig = {
          ...this.introReqConfig,
          selectedConnector: this.noConnectorConfig,
          salutation: '',
          ackContent: '',
          categoryContent: '',
          posContent: '',
          hasGhostContent: '',
          theAsk: '',
          closing: '',
        };
      }
      this.bodyTarget.value = '';
      this.writeIntroductionRequest();
      this.toggleSubmitButton();
    } else {
      this.connectorSelected = true;
      this.toggleSubmitButton();
    }
    this.connectorTargets.forEach(el => {
      if (el !== connectorCard) {
        el.classList.remove('selected');
        el.classList.add('ineligible');
      }
    });
  }

  toggleSubmitButton() {
    const isEmailValidOrNotProvided = this.isEmailValidOrNotProvided();
    const isValid = this.validate();
    if (this.connectorSelected && this.posSelected && isEmailValidOrNotProvided && isValid) {
      this.submitButtonTargets.forEach((btn) => btn.removeAttribute('disabled'));
    } else {
      this.submitButtonTargets.forEach((btn) => btn.setAttribute('disabled', 'disabled'));
    }
  }

  disableEmailOption() {
    document.querySelector("#request-method-disabled-message").classList.remove("hide")
    document.querySelector("#request-method-options").classList.add("hide")
    document.querySelector(".email-submit-button").classList.add("hide")
    document.querySelector(".small-world-submit-button").classList.remove("hide")
  }

  restoreEmailOption() {
    document.querySelector("#request-method-disabled-message").classList.add("hide")
    document.querySelector("#request-method-options").classList.remove("hide")
    const selectedMethod = document.querySelector('input[name="intro_request_method"]:checked').value
    this.swapSubmitButton(selectedMethod)
  }

  validatePosDropdown() {
    this.posSelected = !!this.posTarget.value;
    this.toggleSubmitButton();
  }

  toggleProspectEmail(event) {
    if (event.target.checked) {
      this.prospectEmailTarget.classList.remove('hide');
      this.prospectEmailInputTarget.setAttribute('required', 'required');
    } else {
      this.prospectEmailTarget.classList.add('hide');
      this.prospectEmailInputTarget.removeAttribute('required');
    }
  }

  stopClickProp(event) {
    event.stopPropagation();
  }

  selectOnlyCard() {
    if (this.connectorTargets.length !== 1) return;

    const onlyConnectorCard = this.connectorTargets[0];

    onlyConnectorCard.click()

    this.introReqConfig.selectedConnector = this.noConnectorConfig;

    this.toggleSubmitButton();
  }

  validate() {
    return (this.isEmailValidOrNotProvided() && this.isFormValid() && this.bodyTarget.value.trim());
  }

  isFormValid() {
    return this.formTarget.checkValidity();
  }


  previewEmail() {
    const modal = document.querySelector('#modal-preview_connector_email')
    const preview = modal.querySelector('#content-preview')

    const clearContent = () => {
      while (preview.hasChildNodes()) {
        preview.removeChild(preview.lastChild)
      }
    }

    const showLoadingContent = () => {
      const loading = document.createElement('div')
      loading.setAttribute('class', 'loading')
      loading.textContent = 'Generating Email'

      clearContent()
      preview.append(loading)
    }

    const showPreviewContent = (text) => {
      const parser = new DOMParser()
      const html = parser.parseFromString(text, 'text/html')

      const email = document.createElement('div')
      email.setAttribute('id', 'content-preview-wrapper')
      email.setAttribute('class', 'email-body') // <-- "namespace" to not clash with site css

      const style = html.querySelector('style')
      style.textContent = style.textContent.replace('body', '.email-body') // <-- same as above
      email.appendChild(style)

      const body = html.querySelector('body')
      Array.from(body.children).forEach((child) => {
        // todo: replace each \r\n with <br/> ?
        email.appendChild(child)
      })

      clearContent()
      preview.append(email)
    }

    showLoadingContent()
    //
    // Showing and hiding should probably be done by the modal itself.
    // modal.querySelector('#content-preview').replaceWith(preview)
    modal.classList.remove('hide')

    fetch('/people/preview_connector_email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.token,
      },
      body: JSON.stringify({
        email: this.prospectEmailInputTarget.value,
        prospect: this.prospectTarget.value,
        pos: this.posTarget.value,
        ghost: this.ghostEmailTarget.value,
      })
    }).then((response) => {
      return response.text()

    }).then((text) => {
      showPreviewContent(text)

    })
  }

  insertContent(newContent = '', prevContent = '') {

    const isBlank = (content) => {
      if (content == undefined) return true
      return content.match(/^\s*$/) !== null
    }

    // this can happen with the target company category
    if (isBlank(newContent) && isBlank(prevContent)) return

    //
    // If content is empty, content is newContent
    if (isBlank(this.bodyTarget.value)) {
      this.bodyTarget.value = `${newContent}\n\n`
      return
    }

    let newIndex = -1
    if (!isBlank(newContent)) {
      newIndex = this.bodyTarget.value.indexOf(newContent)
    }

    let prevIndex = -1
    if (!isBlank(prevContent)) {
      prevIndex = this.bodyTarget.value.indexOf(prevContent)
    }

    if (isBlank(newContent) && prevIndex !== -1) { // delete previous content
      let before = this.bodyTarget.value.slice(0, prevIndex) // up to the content to be removed
      if (before.at(-1) === '\n') before = before.slice(0, -1)
      if (before.at(-1) === '\n') before = before.slice(0, -1) // omg

      const after = this.bodyTarget.value.slice(prevIndex + prevContent.length) // everything after

      this.bodyTarget.value = [before, after].join('')
      return
    }

    if ((newIndex === -1 || newIndex !== -1) && prevIndex !== -1) { // replace previous content
      const before = this.bodyTarget.value.slice(0, prevIndex)
      const after = this.bodyTarget.value.slice(prevIndex + prevContent.length)

      // Don't worry about the \n thing here, replacing just the content.
      this.bodyTarget.value = [before, newContent, after].join('')
      return
    }

    if (newIndex === -1 && prevIndex === -1) { // insert new content

      let closingIndex = -1
      if (!isBlank(this.introReqConfig.closing)) {
        closingIndex = this.bodyTarget.value.indexOf(this.introReqConfig.closing)
      }

      let theAskIndex = -1
      if (!isBlank(this.introReqConfig.theAsk)) {
        theAskIndex = this.bodyTarget.value.indexOf(this.introReqConfig.theAsk)
      }

      if (theAskIndex === -1 && closingIndex === -1) { // append to the end then

        let toAppend = `${newContent}` // this is the end of content, don't append any \n
        if (this.bodyTarget.value.at(-1) !== '\n') {
          toAppend = `\n${toAppend}`
        }

        this.bodyTarget.value = this.bodyTarget.value.concat(toAppend)
        return
      }

      let index = -1
      if (theAskIndex > -1 && closingIndex > -1) {
        index = Math.min(theAskIndex, closingIndex)
      } else {
        index = Math.max(theAskIndex, closingIndex)
      }

      const before = this.bodyTarget.value.slice(0, index)
      const after = this.bodyTarget.value.slice(index)

      const toInsert = `${newContent}\n\n`
      this.bodyTarget.value = [before, toInsert, after].join('')

      this.toggleSubmitButton()
      return;
    }
  }

  writeIntroductionRequest() {
    if (!this.generateValue) return // person prefers to not have the request generated
    const content = [
      this.writeAcknowledgement(),
      this.writePointOfSale(),
      this.writeIntent(),
      // this.writeHasGhostEmail(), // exclude this blurb per DR
      this.writeThanks(),
    ].join('') // no space join, so add a space to end of sentences.

    this.toggleSubmitButton();

    this.bodyTarget.value = `${content}\n`
  }

  writeThanks() {
    return `\n\nThanks and let me know if you have any questions! `
  }

  writeSalutation() {
    const connectorFirstName = this.introReqConfig.selectedConnector?.source?.firstName;

    this.introReqConfig.salutation = connectorFirstName ? `Hey ${connectorFirstName},` : "Hello,";

    this.insertContent(this.introReqConfig.salutation);
  }

  writeClosing() {
    const [firstName] = this.requesterValue.split(/\s+/)
    this.introReqConfig.closing = `Thanks!\n${firstName}`
    this.insertContent(this.introReqConfig.closing)
  }

  writeTheAsk() {
    this.introReqConfig.theAsk = 'Any chance you can help with a warm introduction?'
    this.insertContent(this.introReqConfig.theAsk, '')
  }

  writeAcknowledgement() {
    const {
      strength,
      inferred, // Unused?
      creationSource,
      source
    } = this.introReqConfig.selectedConnector || {};
    const connectorFirstName = source?.firstName || '';

    let content = '';

    if (strength === 'strong' || strength === 'very_strong') {
      content = `given your relationship with ${this.prospectValue}, I was hoping you could introduce me as `;
    } else if (strength === 'avg' || strength === 'weak' || strength === 'very_weak') {
      content = `I realize you may not know ${this.prospectValue} very well, but I was hoping you could introduce me as `;
    } else {
      if (creationSource === 'work_history') {
        content = `I don't know how strong your relationship is with ${this.prospectValue}, but given your work history I was hoping you could introduce me as `;
      } else {
        content = `I don't know how strong your relationship is with ${this.prospectValue}, but I was hoping you could introduce me as `;
      }
    }

    // If connectorFirstName exists, prepend it to the content string
    if (connectorFirstName) {
      content = `${connectorFirstName}, ` + content;
    } else {
      // Else, capitalize the first letter of the content string
      content = content.charAt(0).toUpperCase() + content.slice(1);
    }

    this.toggleSubmitButton()

    this.introReqConfig.ackContent = content;
    return content;
  }

  writeTargetCategory() {
    let content = ''

    switch (this.categoryValue) {
      case 'new_business':
        content = `${this.companyValue} would be a brand new customer.`
        break
      case 'expansion':
        content = `${this.companyValue} is an existing customer that we’re looking to expand our relationship with.`
        break
      case 'retention':
        content = `${this.companyValue} is an existing customer and we’re looking to retain them.`
        break
    }

    this.toggleSubmitButton()

    this.insertContent(content, this.introReqConfig.categoryContent)
    this.introReqConfig.categoryContent = content
  }

  writePointOfSale() {

    const companyName = this.companyValue
    const [prospectFirstName] = this.prospectValue.split(/\s+/)

    let content = ''
    const priorityDescription = this.bodyTarget.dataset.priorityDescription // this is it

    const isHighPriority = this.bodyTarget.dataset.highPriority &&
      this.bodyTarget.dataset.highPriority.toLowerCase() === 'true'

    if (isHighPriority) {
      content = 'this is an account that we’ve deemed “high-priority” internally so an introduction here could be very impactful.'
      if (priorityDescription.length > 0) {
        content = `${content} In fact, here are just a few more details I’ve added:\n\n${priorityDescription}`
      }
    } else {
      switch (this.posTarget.value) {
        case 'prospect':
          content = `we’d love to connect with the team at ${companyName} for an introductory call. `
          break
        case 'active':
          content = `we’re currently working on an active opportunity at ${companyName} and connecting with ${prospectFirstName} could be super helpful in our sales process. `
          break
        case 'decision':
          content = `we have a decision pending at ${companyName} and connecting with ${prospectFirstName} could be super helpful in our sales process. `
          break
        default:
          content = `connecting with ${prospectFirstName} could be super helpful. `
          break
      }
    }

    // if (this.connectorIsSelected()) {
    //   this.insertContent(content, this.introReqConfig.posContent)
    // }
    this.toggleSubmitButton()

    this.introReqConfig.posContent = content
    return content
  }

  writeIntent() {
    if (!this.bodyTarget.dataset.intentSource) {
      return
    }

    return `We have new data and insights from an intent tool we use called ${this.bodyTarget.dataset.intentSource} that would indicate ${this.companyValue} is evaluating our solutions right now. As a result, the timing and relevancy of an introduction would align with these intent signals.`
  }

  writeHasGhostEmail() {
    let content = `To make it easier and provide context for the request, I’ve authored a ghost email that you can forward as part of this request if helpful. `

    // this.insertContent(content, this.introReqConfig.hasGhostContent)
    this.introReqConfig.hasGhostContent = content
    return content
  }

  friendlyStrength(strength) {
    const strengthMapping = {
      very_strong: 'Very Strong 🔥',
      strong: 'Strong 💪',
      avg: 'Average 🤝',
      weak: 'Weak 👎',
      very_weak: 'No Relationship ❓'
    };

    return strengthMapping[strength] || '';
  }

  friendlySource(source) {
    const sourceMapping = {
      recommendation: 'Recommendation',
      endorsement: 'Endorsement',
      linkedin_import: ' on LinkedIn',
      gmail_sync: ' through your Google Contacts'
    };

    return sourceMapping[source] || '';
  }
  // If the requester types into the Intro Body
  // textarea then this function will handle it
  handleInput() {
    // This prevents the context from being generated
    // thus overwriting the user's input
    this.toggleSubmitButton();
    this.generateValue = false;
  }

  isEmailValidOrNotProvided() {
    const email = this.prospectEmailInputTarget.value;

    // If email is not provided, return true
    if (!email) return true;

    // If email is provided, validate its format
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

  swapSubmitButton(newRequestMethod) {
    this.submitButtonTargets.forEach((btn) => {
      btn.classList.add('hide')
      if (btn.dataset.introductionRequestMethod === newRequestMethod) {
        btn.classList.remove('hide')
      }
    })
  }

  async updateIntroRequestMethod({ currentTarget }) {
    const body = {
      key: 'intro_request_method',
      value: currentTarget.value,
    }

    const { err } = await tryFetch('/preferences', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.token,
      },
      body: JSON.stringify(body),
    })

    if (err) {
      toastr.error('Sorry, there was an error setting your preferred method for introduction requests.')
      return
    }

    this.swapSubmitButton(currentTarget.value)
    if (currentTarget.value == 'email' && (!this.emailClientAccessValue || this.emailClientAccessValue === 'dont_ask')) {
      this.openEmailClientAccessModal()
    }
  }

  async fetchGeneratedLink(requestBody) {
    const { err, res } = await tryFetch('/introductions/generate_email_link', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.token,
      },
      body: JSON.stringify(requestBody),
    })

    if (err) {
      return { err: "Sorry, there was an error fetching the introduction email link.", res: null }
    }

    try {
      return await res.json()
    } catch (e) {
      console.error("Error processing introduction email link", e)
      return { err: "Sorry, there was an error processing the introduction email link.", res: null }
    }
  }

  async launchEmail() {
    const selectedRelationship = this.relationshipTargets.find((rt) => rt.checked)

    const requestBody = {
      relationshipId: selectedRelationship.value,
      connectorMessage: this.bodyTarget.value,
      prospectMessage: this.ghostEmailTarget.value
    }

    const { err, uri } = await this.fetchGeneratedLink(requestBody)
    if (err) {
      toastr.error(err)
    }
    if (uri) {
      try {
        // setTimeout is a workaround for Safari's popup blocker
        setTimeout(() => { window.open(uri, '_blank').focus() })
      } catch (e) {
        console.error("Error opening introduction email link", e)
        toastr.error('Sorry, there was an error opening the introduction email link.')
      }
    }
  }

  async selectEmailClientAccess(accessPreference) {
    const body = {
      key: 'email_client_access',
      value: accessPreference,
    }

    const response = await fetch('/preferences', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.token,
      },
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      console.error("Error setting email client access preference", response)
      toastr.error('Sorry, there was an error setting your preferred method for accessing email.')
      return
    }

    this.emailClientAccessValue = accessPreference
    this.closeEmailClientAccessModal()
  }

  openEmailClientAccessModal() {
    const modal = document.querySelector('#modal-ask_email_client')
    modal.classList.remove('hide')
  }

  closeEmailClientAccessModal() {
    const modal = document.querySelector('#modal-ask_email_client')
    modal.classList.add('hide')
  }

  selectEmailAccessBrowser(event) {
    event.preventDefault()
    this.selectEmailClientAccess('web')
  }

  selectEmailAccessApp(event) {
    event.preventDefault()
    this.selectEmailClientAccess('native')
  }

  prepareLaunchEmail(event) {
    if (!this.validate()) {
      // Piggy back on the built in validation
      return;
    }

    // If validation passes, bypass the normal submit pathway
    event.preventDefault();

    // If the user has not set a preference for email client access, open the selection modal
    if (!this.emailClientAccessValue || this.emailClientAccessValue === 'dont_ask') {
      this.openEmailClientAccessModal()
    } else {
      this.launchEmail()
    }
  }
}
