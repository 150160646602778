import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ 'pill', 'menu', 'count' ]

  static values = {
    filterKey: String,
    optionCount: Number,
    selectedCount: Number,
    callback: String,
    initSelectAndDisableAll: Boolean,
  }

  toggle() {
    this.menuTarget.classList.toggle('hide')
  }

  // TODO: would be nice to ease this outta sight
  close() {
    this.menuTarget.classList.add('hide')
  }

  closeExternally(event) {
    if (!this.element.contains(event.target)) {
      this.menuTarget.classList.add('hide')
    }
  }

  select({ currentTarget }) {
    const optionId = currentTarget.dataset.optionid
    const checkbox = this.element.querySelector(`input[type="checkbox"][value="${optionId}"]`)
    checkbox.checked = !checkbox.checked
    this.recount()
  }

  add(optionId) {
    const index = this.selectedValue.findIndex((id) => id === optionId)
    if (index === -1) {
      const dupe = this.selectedValue
      dupe.push(optionId)
      this.selectedValue = dupe
    }
  }

  remove(optionId) {
    const index = this.selectedValue.findIndex((id) => id === optionId)
    const dupe = this.selectedValue
    dupe.splice(index, 1)
    this.selectedValue = dupe
  }

  deselectAll() {
    const checkboxes = this.menuTarget.querySelectorAll('input[type="checkbox"]')
    checkboxes.forEach((checkbox) => checkbox.checked = false)
    this.selectedValue = []
    this.recount()
  }

  selectAll() {
    const checkboxes = this.menuTarget.querySelectorAll('input[type="checkbox"]')
    checkboxes.forEach((checkbox) => checkbox.checked = true)
    this.recount()
  }

  disableAll() {
    const checkboxes = this.menuTarget.querySelectorAll('input[type="checkbox"]')
    checkboxes.forEach((checkbox) => checkbox.disabled = true)
    const options = this.menuTarget.querySelectorAll('span[data-optionid]')
    options.forEach((option) => option.classList.add('disabled'))
  }

  enableAll() {
    const checkboxes = this.menuTarget.querySelectorAll('input[type="checkbox"]')
    checkboxes.forEach((checkbox) => checkbox.disabled = false)
    const options = this.menuTarget.querySelectorAll('span[data-optionid]')
    options.forEach((option) => option.classList.remove('disabled'))
  }

  selectAndDisableAll(event) {
    this.selectAll()
    this.disableAll()
  }

  selectAndEnableAll(event) {
    this.selectAll()
    this.enableAll()
  }

  recount() {
    this.selectedCountValue = this.menuTarget.querySelectorAll('input[type="checkbox"]:checked').length

    this.pillTarget.classList.remove('filter-pill-none')
    this.pillTarget.classList.remove('filter-pill-all')
    this.menuTarget.querySelector('.show-results').classList.remove('disabled')

    if (this.selectedCountValue === 0) {
      this.pillTarget.classList.add('filter-pill-none')
      this.menuTarget.querySelector('.show-results').classList.add('disabled')
    }

    if (this.selectedCountValue === this.optionCountValue) {
      this.pillTarget.classList.add('filter-pill-all')
    }

    this.countTarget.textContent = this.selectedCountValue
  }

  filter() {
    // The whole filter-pill:filter->dashboard#makeRequest
    // wasn't working, let's rely on good old fashioned js.
    this.element.dispatchEvent(new CustomEvent('filtered', {
      detail: {
        filterName: this.filterKeyValue,
        selected: Array.from(this.selectedOptions()).map((option) => option.value),
      },
      bubbles: true,
    }))

    this.close()
  }

  options() {
    return this.element.querySelectorAll('input[type="checkbox"]')
  }

  selectedOptions() {
    return this.element.querySelectorAll('input[type="checkbox"]:checked')
  }

  connect() {
    this.optionCountValue = this.options().length
    this.recount()
  }

  initialize() {
    if (this.initSelectAndDisableAllValue) {
      this.selectAndDisableAll() 
    }
  }

  handleFilterPillEvent(event) {
    if (event.detail.filterName !== this.filterKeyValue) return
    switch (event.detail.action) {
      case 'selectAndDisableAll':
        return this.selectAndDisableAll(event)
      case 'selectAndEnableAll':
        return this.selectAndEnableAll(event)
    }
  }
}
